var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formDataUtama"},[_c('section',{attrs:{"id":"ubah-surat-permohonan"}},[_c('b-card',{attrs:{"header":"Ubah Surat Permohonan","header-tag":"h4","header-class":"border-bottom mb-2"}},[(_vm.alert)?[_c('b-alert',{staticClass:"mt-2",attrs:{"variant":_vm.alertDetail.variant,"show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm.alertDetail.title)+" ")]),_c('div',{staticClass:"alert-body"},[(typeof _vm.alertDetail.text === 'object')?_c('ul',_vm._l((_vm.alertDetail.text),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(index)+": "),_vm._l((item),function(child,indexs){return _c('span',{key:indexs},[_vm._v(" "+_vm._s(child)+" ")])})],2)}),0):_c('span',[_vm._v(_vm._s(_vm.alertDetail.text))])])])]:_vm._e(),(_vm.form.year_budget !== 2023 && (_vm.form.is_verified_asdep === -1 || _vm.form.is_verified_bapenas === -1))?[_c('b-alert',{attrs:{"variant":"warning","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Catatan Perbaikan ")]),_c('div',{staticClass:"alert-body"},[(typeof _vm.form.keterangan === 'object')?_c('ul',_vm._l((_vm.form.keterangan),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(index)+": "),_vm._l((item),function(child,indexs){return _c('span',{key:indexs},[_vm._v(" "+_vm._s(child)+" ")])})],2)}),0):_c('span',[_vm._v(_vm._s(_vm.form.keterangan))])])])]:_vm._e(),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nomor Surat","label-for":"nomorSurat"}},[_c('validation-provider',{attrs:{"name":"Nomor Surat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nomorSurat","name":"letter_number","placeholder":"Nomor Surat","required":""},model:{value:(_vm.form.letter_number),callback:function ($$v) {_vm.$set(_vm.form, "letter_number", $$v)},expression:"form.letter_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tahun Anggaran","label-for":"vi-year_budget"}},[_c('validation-provider',{attrs:{"name":"Tanggal Surat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-year_budget","required":"","name":"year_budget","options":_vm.year_budget,"placeholder":"Tahun Anggaran"},model:{value:(_vm.form.year_budget),callback:function ($$v) {_vm.$set(_vm.form, "year_budget", $$v)},expression:"form.year_budget"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Perihal Surat","label-for":"perihalSurat"}},[_c('validation-provider',{attrs:{"name":"Perihal Surat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"perihalSurat","name":"perihal","placeholder":"Perihal Surat","required":""},model:{value:(_vm.form.perihal),callback:function ($$v) {_vm.$set(_vm.form, "perihal", $$v)},expression:"form.perihal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tanggal Surat","label-for":"tanggalSurat"}},[_c('validation-provider',{attrs:{"name":"Tanggal Surat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"tanggalSurat","date-format-options":{ year: 'numeric', month: 'short', day: 'numeric' },"max":_vm.today,"name":"tanggal_surat","placeholder":"Tanggal Surat","required":""},model:{value:(_vm.form.tanggal_surat),callback:function ($$v) {_vm.$set(_vm.form, "tanggal_surat", $$v)},expression:"form.tanggal_surat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Jabatan Pengirim","label-for":"jabatan"}},[_c('validation-provider',{attrs:{"name":"Jabatan Pengirim","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"jabatan","name":"jabatan","placeholder":"Jabatan Pengirim","required":""},model:{value:(_vm.form.jabatan),callback:function ($$v) {_vm.$set(_vm.form, "jabatan", $$v)},expression:"form.jabatan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Jumlah Kegiatan","label-for":"jumlahKegiatan"}},[_c('validation-provider',{attrs:{"name":"Jumlah Kegiatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"jumlahKegiatan","name":"total_program","type":"number","placeholder":"Jumlah Kegiatan","required":""},model:{value:(_vm.form.total_program),callback:function ($$v) {_vm.$set(_vm.form, "total_program", $$v)},expression:"form.total_program"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nama Penanggung Jawab Permohonan","label-for":"pic_name"}},[_c('validation-provider',{attrs:{"name":"Nama Penanggung Jawab Permohonan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"pic_name","name":"pic_name","placeholder":"Nama Penanggung Jawab Permohonan"},model:{value:(_vm.form.pic_name),callback:function ($$v) {_vm.$set(_vm.form, "pic_name", $$v)},expression:"form.pic_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"No. Kontak Penanggung Jawab Permohonan","label-for":"pic_number"}},[_c('validation-provider',{attrs:{"name":"No. Kontak Penanggung Jawab Permohonan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"pic_number","name":"pic_number","type":"number","placeholder":"No. Kontak Penanggung Jawab Permohonan","required":""},model:{value:(_vm.form.pic_number),callback:function ($$v) {_vm.$set(_vm.form, "pic_number", $$v)},expression:"form.pic_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Ubah File Surat Permohonan Clearance (dalam format PDF)","label-for":"file_surat"}},[_c('b-form-file',{staticClass:"mt-1",attrs:{"name":"file_surat"},on:{"input":_vm.checkFile},model:{value:(_vm.file1),callback:function ($$v) {_vm.file1=$$v},expression:"file1"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Ubah File Peta Rencana Kegiatan (dalam format PDF)","label-for":"file_prk"}},[_c('b-form-file',{staticClass:"mt-1",attrs:{"name":"file_prk"},on:{"input":_vm.checkFile},model:{value:(_vm.file2),callback:function ($$v) {_vm.file2=$$v},expression:"file2"}})],1)],1),_c('b-col',{staticClass:"mb-1"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Submit ")])],1)],1)],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }